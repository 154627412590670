import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const Gallery = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <nav
        class="flex pt-[120px] justify-center items-center"
        aria-label="Breadcrumb"
      >
        <ol class="inline-flex items-center space-x-1 md:space-x-3 p-6">
          <li class="inline-flex items-center">
            <Link
              to="/"
              class="inline-flex items-center text-sm font-medium text-gray-900 hover:text-Primary-A"
            >
              <svg
                class="mr-2 w-4 h-4"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
              </svg>
              Home
            </Link>
          </li>
          <li aria-current="page">
            <div class="flex items-center">
              <svg
                class="w-6 h-6 text-blue-800"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span class="ml-1 text-sm font-medium text-Primary-A md:ml-2">
                Gallery
              </span>
            </div>
          </li>
        </ol>
      </nav>
      <div className="w-full py-[10rem] px-4">
        <div className="max-w-[1240px] mx-auto grid md:grid-cols-3 gap-8 ">
          <div className="w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300">
            <h2 className="text-2xl font-bold text-center py-8 ">Teams</h2>
            <Link
              to="/teams"
              className="inline-flex items-center justify-center  w-[200px] rounded-md font-medium my-6 mx-auto py-3 text-black bg-Primary-A hover:bg-blue-900 focus:shadow-outline focus:outline-none"
            >
              View more
            </Link>
          </div>
          <div className="w-full shadow-xl flex flex-col p-4 md:m-0 my-8 rounded-lg hover:scale-105 duration-300">
            <h2 className="text-2xl font-bold text-center py-8 ">Projects</h2>
            <Link
              to="/projects"
              className="inline-flex items-center justify-center  w-[200px] rounded-md font-medium my-6 mx-auto py-3 text-black bg-blue-800 hover:bg-Primary-A focus:shadow-outline focus:outline-none"
            >
              View more
            </Link>
          </div>
          <div className="w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300">
            <h2 className="text-2xl font-bold text-center py-8 ">
              Entertainment
            </h2>
            <Link
              to="/entertainment"
              className="inline-flex items-center justify-center  w-[200px] rounded-md font-medium my-6 mx-auto py-3 text-black bg-Primary-A hover:bg-blue-900 focus:shadow-outline focus:outline-none"
            >
              View more
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Gallery;
