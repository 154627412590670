import React from 'react'
import Clock from './Clock';

const Deadline = () => {

    let deadlinetime = "November 18, 2022 , 9:00 AM";

    return (
        <div>
            <Clock deadline={deadlinetime} />
        </div>
    )
}

export default Deadline