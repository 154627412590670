import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Background from "../../images/bg-1.jpg";
import Nhrc from "../../images/Nhrc.jpeg"
import Deadline from "../Time/Deadline";

const UpComingEvents = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <nav
        class="flex pt-[120px] justify-center items-center"
        aria-label="Breadcrumb"
      >
        <ol class="inline-flex items-center space-x-1 md:space-x-3 p-6">
          <li class="inline-flex items-center">
            <Link
              to="/"
              class="inline-flex items-center text-sm font-medium text-gray-900 hover:text-Primary-A"
            >
              <svg
                class="mr-2 w-4 h-4"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
              </svg>
              Home
            </Link>
          </li>
          <li aria-current="page">
            <div class="flex items-center">
              <svg
                class="w-6 h-6 text-blue-800"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span class="ml-1 text-sm font-medium text-Primary-A md:ml-2">
                Events
              </span>
            </div>
          </li>
          <li aria-current="page">
            <div class="flex items-center">
              <svg
                class="w-6 h-6 text-blue-800"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span class="ml-1 text-sm font-medium text-Primary-A md:ml-2">
                Upcoming Events
              </span>
            </div>
          </li>
        </ol>
      </nav>
      <div className="relative pt-[10vh] mb-20">
        <img
          src={Background}
          className="absolute inset-0 w-full h-full lg:h-[100vh]"
          alt=""
        />
        <div className="relative z-10">
          <div className="px-2 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-2">
            <h2 className="mb-6 font-sans text-center text-5xl font-bold tracking-wider text-gray-200 sm:leading-none">
            <a href="https://artticon2022.com/" className="underline hover:text-blue-800" >ARTTICON</a> <span className="underline text-blue-500">2022</span>
            </h2>
            <h2 className="mb-6 font-sans text-center text-2xl font-semibold text-gray-200 sm:leading-none">
              26th National Conference of <br />
            </h2>
            <h3 className="mb-6 font-sans text-center text-2xl font-semibold text-gray-200 sm:leading-none">
              Association of Radiation Therapy Technologists Of <br />
              India
            </h3>
            <h4 className="mb-10 font-sans text-center text-xl font-base text-gray-200 sm:leading-none">
              First of its kind being conducted outside India in (Kathmandu ,
              Nepal)
            </h4>
            <div className="mb-6 grid gap-8 row-gap-10 sm:grid-cols-1 lg:grid-cols-3">
              <div className="flex justify-center items-center">
                <img src={Nhrc} alt="rounded" className="rounded h-48 w-64 " />
              </div>
              <div className="flex justify-center flex-col items-center">
                <h1 className="text-gray-200 text-3xl font-extrabold text-center mb-6">
                  Conference Theme
                </h1>
                <p className="text-center  text-2xl font-bold text-red-500">
                  Exploring new research and developments in Radiotherapy
                  <br />
                  <span className="text-blue-800">An International Approach</span>
                </p>
              </div>
              <div className="flex justify-center flex-col items-center">
                <h1 className="text-gray-200 text-3xl font-extrabold text-center mb-8">
                  Time & Date
                </h1>
                <p className="text-center  text-3xl font-bold tracking-wider transition-colors duration-200 text-red-500">
                  9:00 AM <br /> 2022/11/18 - 2022/11/19
                </p>
              </div>
            </div>
            <p className="text-center  mt-6 mb-4 text-xl font-bold text-gray-400 md:text-2xl">
              <Deadline />
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpComingEvents;
