import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const PastCommittee = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <nav
        class="flex pt-[120px] justify-center items-center"
        aria-label="Breadcrumb"
      >
        <ol class="inline-flex items-center space-x-1 md:space-x-3 p-6">
          <li class="inline-flex items-center">
            <Link
              to="/"
              class="inline-flex items-center text-sm font-medium text-gray-900 hover:text-Primary-A"
            >
              <svg
                class="mr-2 w-4 h-4"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
              </svg>
              Home
            </Link>
          </li>
          <li aria-current="page">
            <div class="flex items-center">
              <svg
                class="w-6 h-6 text-blue-800"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span class="ml-1 text-sm font-medium text-Primary-A md:ml-2">
                Organiztion
              </span>
            </div>
          </li>
          <li aria-current="page">
            <div class="flex items-center">
              <svg
                class="w-6 h-6 text-blue-800"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span class="ml-1 text-sm font-medium text-Primary-A md:ml-2">
                Past Committee
              </span>
            </div>
          </li>
        </ol>
      </nav>
      <div>
        <h2 className="text-center mt-8 text-4xl font-extrabold text-Primary-A leading-none md:pl-2">
          Past Committee
        </h2>
        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div className="grid gap-10 row-gap-8 mx-auto sm:row-gap-10 lg:max-w-screen-lg sm:grid-cols-2 lg:grid-cols-3">
            <div className="flex">
              <div className="flex flex-col justify-center">
                <p className="text-lg font-bold">Mr. Surendra Kumar Mahato</p>
                <p className="text-sm text-gray-800">President</p>
              </div>
            </div>
            <div className="flex">
              <div className="flex flex-col justify-center">
                <p className="text-lg font-bold">Mr. Sobhit Lal Yadav</p>
                <p className="text-sm text-gray-800">Vice president</p>
              </div>
            </div>
            <div className="flex">
              <div className="flex flex-col justify-center">
                <p className="text-lg font-bold">Mr. Shrawan Kumar Thakur</p>
                <p className="text-sm text-gray-800">General secretary</p>
              </div>
            </div>
            <div className="flex">
              <div className="flex flex-col justify-center">
                <p className="text-lg font-bold">Mr. Suresh Kumar Mahato</p>
                <p className="text-sm text-gray-800">Secretary</p>
              </div>
            </div>
            <div className="flex">
              <div className="flex flex-col justify-center">
                <p className="text-lg font-bold">Mr. Santosh Kumar Jha</p>
                <p className="text-sm text-gray-800">Treasurer</p>
              </div>
            </div>
            <div className="flex">
              <div className="flex flex-col justify-center">
                <p className="text-lg font-bold">Mr. Purushottam Kumar Jha</p>
                <p className="text-sm text-gray-800">Member</p>
              </div>
            </div>
            <div className="flex">
              <div className="flex flex-col justify-center">
                <p className="text-lg font-bold">Mr. Rajiv Kumar Jha</p>
                <p className="text-sm text-gray-800">Member</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PastCommittee;
