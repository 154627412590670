import React, { useEffect } from "react";

// for Routes
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// logo
import Logo from "./images/logo.jpg";

// components
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";

// pages
import About from "./pages/About";
import Contact from "./pages/Contact";
import Donation from "./pages/Donation";
import Education from "./pages/Education";
import Home from "./pages/Home";

import PastEvents from "./pages/All_Events/PastEvents";
import UpComingEvents from "./pages/All_Events/UpComingEvents";

import Gallery from "./pages/Gallery/Gallery";

import NewRegistration from "./pages/Memberships/NewRegistration";
import RegisteredMember from "./pages/Memberships/RegisteredMember";

// import Advisors from "./pages/Organization/Team/Advisors";
import CurrentCommittee from "./pages/Organization/CurrentCommittee";
// import Members from "./pages/Organization/Team/Members";
import PastCommittee from "./pages/Organization/PastCommittee";

import FounderCommittee from "./pages/Organization/FounderCommittee"

import Entertainment from "./pages/Gallery/Albums/Entertainment";
import Projects from "./pages/Gallery/Albums/Projects";
import Teams from "./pages/Gallery/Albums/Teams";

function App() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (

    <div className="bg-gray-200">
      <Router>

        <Navbar Logo={Logo} />

        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/about" exact element={<About />} />
          {/* <Route path="/advisors" exact element={<Advisors />} / */}
          <Route path="/contact" exact element={<Contact />} />
          <Route path="/currentcommittee" exact element={<CurrentCommittee />} />
          <Route path="/donation" exact element={<Donation />} />
          <Route path="/education" exact element={<Education />} />
          <Route path="/entertainment" exact element={<Entertainment />} />
          <Route path="/gallery" exact element={<Gallery />} />
          <Route path="/foundercommittee" exact element={<FounderCommittee />} />
          <Route path="/newregistration" exact element={<NewRegistration />} />
          <Route path="/pastcommittee" exact element={<PastCommittee />} />
          <Route path="/pastevents" exact element={<PastEvents />} />
          <Route path="/projects" exact element={<Projects />} />
          <Route path="/registeredmember" exact element={<RegisteredMember />} />
          <Route path="/teams" exact element={<Teams />} />
          <Route path="/upcomingevents" exact element={<UpComingEvents />} />
        </Routes>

        <Footer Logo={Logo} />

      </Router>
    </div>
    
  );
}

export default App;
