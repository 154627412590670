import React,{useEffect} from 'react'
import Banner from '../components/Banner'
import Slider from '../components/Slider'

const Home = () => {
    useEffect(() => {
      window.scrollTo(0,0)
    }, [])
    
  return (
    <>
    <Slider />
    <div className="px-4 py-8 md:px-24 lg:px-8 lg:py-8">
      <div className=" my-10 sm:text-center md:mb-12">
        <h2 className=" mb-6 font-sans text-2xl font-bold leading-10 tracking-wider uppercase text-blue-900 sm:text-4xl ">
            Nepal Association Of RadioTherapy Technologists <span className='text-red-800'>( Nart )</span>
        </h2>
      </div>
    </div>
    <Banner />
    </>
  )
}

export default Home
