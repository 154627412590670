import React from "react";
import { Link } from "react-router-dom";

const Banner = () => {
  return (
    <div className="px-4 pb-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:pb-20">
      <div className="grid max-w-screen-lg gap-8 row-gap-5 md:row-gap-8 sm:mx-auto lg:grid-cols-2">
        <div className="transition duration-300 transform bg-white rounded shadow-sm hover:-translate-y-1 hover:shadow md:text-center">
          <div className="relative">
            <img
              className="object-cover w-full h-64 rounded-t lg:h-80 xl:h-96"
              src="https://images.pexels.com/photos/3182796/pexels-photo-3182796.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;h=750&amp;w=1260"
              alt=""
            />
            <div className="absolute inset-0 bg-gray-800 bg-opacity-25" />
          </div>
          <div className="px-6 py-8 border border-t-0 rounded-b sm:px-8">
            <h5 className="mb-2 text-xl font-bold leading-none sm:text-2xl">
              About Us
            </h5>
            <p className="mb-5 text-gray-700">
              Nepal Association of Radiotherapy Technologists (NART) is a
              non-government, not-for-profit and voluntary professional
              organization of the Radiotherapy Technologists (RTTs) in Nepal. It
              is the only and national organization that includes all
              radiotherapy technology personnel in Nepal under a common roof for
              their professional interests and benefits.
            </p>
            <Link
              to="/about"
              className="inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-Primary-A hover:bg-blue-900 focus:shadow-outline focus:outline-none"
            >
              Read more
            </Link>
          </div>
        </div>
        <div className="transition duration-300 transform bg-white rounded shadow-sm hover:-translate-y-1 hover:shadow md:text-center">
          <div className="relative">
            <img
              className="object-cover w-full h-64 rounded-t lg:h-80 xl:h-96"
              src="https://images.pexels.com/photos/3184360/pexels-photo-3184360.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;h=750&amp;w=1260"
              alt=""
            />
            <div className="absolute inset-0 bg-gray-800 bg-opacity-25" />
          </div>
          <div className="px-6 py-8 border border-t-0 rounded-b sm:px-8">
            <h5 className="mb-2 text-xl font-bold leading-none sm:text-2xl">
              Events
            </h5>
            <p className="mb-5 text-gray-700">
              ARTTICON 2022 26th National Conference of Association of Radiation
              Therapy Technologists Of India First of its kind being conducted
              outside India. <br /> Venu: Kathmandu , Nepal <br /> Date: 2022/11/18 - 2022/11/19 <br/>
              Time: 9:00 AM
            </p>
            <Link
              to="/upcomingevents"
              className="inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-blue-800 hover:bg-Primary-A focus:shadow-outline focus:outline-none"
            >
              See more
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
