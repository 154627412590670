import React, { useEffect, useState } from "react";

const Clock = ({ deadline }) => {
    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);

    const leading0 = (num) => {
        return num < 10 ? "0" + num : num;
    };

    const getTimeUntil = (deadline) => {
        const time = Date.parse(deadline) - Date.parse(new Date());
        if (time < 0) {
            setDays(0);
            setHours(0);
            setMinutes(0);
            setSeconds(0);
        } else {
            setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
            setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
            setMinutes(Math.floor((time / 1000 / 60) % 60));
            setSeconds(Math.floor((time / 1000) % 60));
        }
    };

    useEffect(() => {
        setInterval(() => getTimeUntil(deadline), 1000);

        return () => getTimeUntil(deadline);
    }, [deadline]);

    return (
        
        <div className="nav-link">
            <span className="text-blue-900 md:text-5xl">{leading0(days)}</span>{" "}
      <span className="text-red-500 mr-8">Days</span>
      <span className="text-blue-900 md:text-5xl">{leading0(hours)}</span>{" "}
      <span className="text-red-500 mr-8">Hours</span>
      <br className="sm:block md:hidden" />
      <span className="text-blue-900 md:text-5xl"> {leading0(minutes)}</span>{" "}
      <span className="text-red-500 mr-8">Minutes</span>
      <span className="text-blue-900 md:text-5xl"> {leading0(seconds)}</span>{" "}
      <span className="text-red-500 mr-8">Seconds</span></div>
    );
};

export default Clock;