import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import President from "../../images/President.jpg";
import VicePresident from "../../images/Vice-President.jpg";
import GeneralSecretary from "../../images/General-Secretary.jpg";
import Treasurer from "../../images/Treasurer.jpg"
import Secretary from "../../images/Secretary.jpg";
import MemberII from "../../images/MemberII.jpg";
import Member from "../../images/Member.jpg";

const CurrentCommittee = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <nav
        class="flex pt-[120px] justify-center items-center"
        aria-label="Breadcrumb"
      >
        <ol class="inline-flex items-center space-x-1 md:space-x-3 p-6">
          <li class="inline-flex items-center">
            <Link
              to="/"
              class="inline-flex items-center text-sm font-medium text-gray-900 hover:text-Primary-A"
            >
              <svg
                class="mr-2 w-4 h-4"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
              </svg>
              Home
            </Link>
          </li>
          <li aria-current="page">
            <div class="flex items-center">
              <svg
                class="w-6 h-6 text-blue-800"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span class="ml-1 text-sm font-medium md:ml-2">Organiztion</span>
            </div>
          </li>
          <li aria-current="page">
            <div class="flex items-center">
              <svg
                class="w-6 h-6 text-blue-800"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span class="ml-1 text-sm font-medium text-Primary-A md:ml-2">
                Current Committee
              </span>
            </div>
          </li>
        </ol>
      </nav>
      <div>
        <h1 className="text-center mt-8 text-4xl font-extrabold text-Primary-A leading-none md:pl-2">
          Members
        </h1>
        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div className="grid gap-10 sm:grid-cols-2 lg:grid-cols-4">
            <div>
              <div className="relative overflow-hidden transition duration-300 transform rounded shadow-lg lg:hover:-translate-y-2 hover:shadow-2xl">
                <img
                  className="object-cover w-full h-56 md:h-64 xl:h-80"
                  src={President}
                  alt="Person"
                />
                <div className="absolute inset-0 flex flex-col justify-center px-5 py-4 text-center transition-opacity duration-300 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
                  <p className="mb-1 text-lg font-bold text-gray-100">
                    MR. RAJEEV KARN
                  </p>
                  <p className="mb-4 text-xs font-bold text-gray-100">
                    PRESIDENT
                  </p>
                  <p className="mb-1 text-xs tracking-wide text-gray-400">
                    +977-9841246120
                  </p>
                  <p className="mb-4 text-xs tracking-wide text-gray-400">
                    rajeevkarn@gmail.com
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="relative overflow-hidden transition duration-300 transform rounded shadow-lg lg:hover:-translate-y-2 hover:shadow-2xl">
                <img
                  className="object-cover w-full h-56 md:h-64 xl:h-80"
                  src={VicePresident}
                  alt="Person"
                />
                <div className="absolute inset-0 flex flex-col justify-center px-5 py-4 text-center transition-opacity duration-300 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
                  <p className="mb-1 text-lg font-bold text-gray-100">
                    MR. SANTOSH KUMAR JHA
                  </p>
                  <p className="mb-4 text-xs font-bold text-gray-100">
                    VICE PRESIDENT
                  </p>
                  <p className="mb-1 text-xs tracking-wide text-gray-400">
                    +977-9845369805
                  </p>
                  <p className="mb-4 text-xs tracking-wide text-gray-400">
                    santoshjha98074@gmail.com
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="relative overflow-hidden transition duration-300 transform rounded shadow-lg lg:hover:-translate-y-2 hover:shadow-2xl">
                <img
                  className="object-cover w-full h-56 md:h-64 xl:h-80"
                  src={GeneralSecretary}
                  alt="Person"
                />
                <div className="absolute inset-0 flex flex-col justify-center px-5 py-4 text-center transition-opacity duration-300 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
                  <p className="mb-1 text-lg font-bold text-gray-100">
                    MR. RAKESH KUMAR JHA
                  </p>
                  <p className="mb-4 text-xs  font-bold text-gray-100">
                    GENERAL SECRETARY
                  </p>
                  <p className="mb-1 text-xs tracking-wide text-gray-400">
                    +977-9861108446
                  </p>
                  <p className="mb-4 text-xs tracking-wide text-gray-400">
                    rakeshnaincy@gmail.com
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="relative overflow-hidden transition duration-300 transform rounded shadow-lg lg:hover:-translate-y-2 hover:shadow-2xl">
                <img
                  className="object-cover w-full h-56 md:h-64 xl:h-80"
                  src={Treasurer}
                  alt="Person"
                />
                <div className="absolute inset-0 flex flex-col justify-center px-5 py-4 text-center transition-opacity duration-300 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
                  <p className="mb-1 text-lg font-bold text-gray-100">
                    MR. PRAPHULLA JHA
                  </p>
                  <p className="mb-4 text-xs font-bold text-gray-100">
                    TREASURER
                  </p>
                  <p className="mb-1 text-xs tracking-wide text-gray-400">
                    +977-9851216888
                  </p>
                  <p className="mb-4 text-xs tracking-wide text-gray-400">
                    luckypraphulla@gmail.com
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="relative overflow-hidden transition duration-300 transform rounded shadow-lg lg:hover:-translate-y-2 hover:shadow-2xl">
                <img
                  className="object-cover w-full h-56 md:h-64 xl:h-80"
                  src={Secretary}
                  alt="Person"
                />
                <div className="absolute inset-0 flex flex-col justify-center px-5 py-4 text-center transition-opacity duration-300 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
                  <p className="mb-1 text-lg font-bold text-gray-100">
                    MR. MANOJ KUMAR SHAH
                  </p>
                  <p className="mb-4 text-xs font-bold text-gray-100">
                    SECRETARY
                  </p>
                  <p className="mb-1 text-xs tracking-wide text-gray-400">
                    +977-9866250957
                  </p>
                  <p className="mb-4 text-xs tracking-wide text-gray-400">
                    MANOJSHAH63834@gmail.com
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="relative overflow-hidden transition duration-300 transform rounded shadow-lg lg:hover:-translate-y-2 hover:shadow-2xl">
                <img
                  className="object-cover w-full h-56 md:h-64 xl:h-80"
                  src={MemberII}
                  alt="Person"
                />
                <div className="absolute inset-0 flex flex-col justify-center px-5 py-4 text-center transition-opacity duration-300 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
                  <p className="mb-1 text-lg font-bold text-gray-100">
                    MR. KUNDAN KUMAR
                  </p>
                  <p className="mb-4 text-xs font-bold text-gray-100">MEMBER</p>
                  <p className="mb-1 text-xs tracking-wide text-gray-400">
                    +977-9845943802
                  </p>
                  <p className="mb-4 text-xs tracking-wide text-gray-400">
                    kundanku083@gmail.com
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="relative overflow-hidden transition duration-300 transform rounded shadow-lg lg:hover:-translate-y-2 hover:shadow-2xl">
                <img
                  className="object-cover w-full h-56 md:h-64 xl:h-80"
                  src={Member}
                  alt="Person"
                />
                <div className="absolute inset-0 flex flex-col justify-center px-5 py-4 text-center transition-opacity duration-300 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
                  <p className="mb-1 text-lg font-bold text-gray-100">
                    MR. SURAJ GURUNG
                  </p>
                  <p className="mb-4 text-xs font-bold text-gray-100">MEMBER</p>
                  <p className="mb-1 text-xs tracking-wide text-gray-400">
                    +977-9845446414
                  </p>
                  <p className="mb-4 text-xs tracking-wide text-gray-400">
                    SURAJGURUNG522@gmail.com
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full py-[5rem] px-4 bg-white ">
        <div className="max-w-[1240px] mx-auto grid md:grid-cols-2 gap-8 ">
          <div className="w-full shadow-xl flex flex-col p-4 my-4 rounded-lg">
            <h2 className="text-2xl font-bold text-center py-8 ">
              FOUNDER PRESIDENT
            </h2>
            <p className="text-center text-3xl font-bold">
              MR. KRISHNA DATTA BHATTA
            </p>
            <div className="text-center font-medium">
              <p className="py-2 border-b mx-8 mt-8">+254706098122</p>
              <p className="py-2 border-b mx-8">dkrishna5@hotmail.com</p>
            </div>
          </div>

          <div className="w-full shadow-xl flex flex-col p-4 my-4 rounded-lg">
            <h2 className="text-2xl font-bold text-center py-8 ">
              IMMEDIATE PAST PRESIDENT
            </h2>
            <p className="text-center text-3xl font-bold">
              MR. SURENDRA KUMAR MAHATO
            </p>
            <div className="text-center font-medium">
              <p className="py-2 border-b mx-8 mt-8">+977-9845242794</p>
              <p className="py-2 border-b mx-8">sunilmrit@gmail.com</p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <h2 className="text-center mt-8 text-4xl font-extrabold text-Primary-A leading-none md:pl-2">
          Advisors
        </h2>
        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div className="grid gap-2 row-gap-2 lg:grid-cols-2">
            <ul className="space-y-2">
              <li className="flex flex-col items-start">
                <span className="mr-1">
                  <svg
                    className="w-5 h-5 mt-px text-blue-900"
                    stroke="currentColor"
                    viewBox="0 0 52 52"
                  >
                    <polygon
                      strokeWidth="4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="none"
                      points="29 13 14 29 25 29 23 39 38 23 27 23"
                    />
                  </svg>
                </span>
                <p className="mb-1 text-lg font-bold text-gray-700">
                  MR. DILIP TAMRAKAR
                </p>
                <p className="mb-1 text-xs tracking-wide text-gray-700">
                  +977-9845446414
                </p>
                <p className="mb-4 text-xs font-bold text-gray-700">
                  dktamrakar@yahoo.com
                </p>
              </li>
              <li className="flex flex-col items-start">
                <span className="mr-1">
                  <svg
                    className="w-5 h-5 mt-px text-blue-900"
                    stroke="currentColor"
                    viewBox="0 0 52 52"
                  >
                    <polygon
                      strokeWidth="4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="none"
                      points="29 13 14 29 25 29 23 39 38 23 27 23"
                    />
                  </svg>
                </span>
                <p className="mb-1 text-lg font-bold text-gray-700">
                MR. RAM CHANDRA SHAH
                </p>
                <p className="mb-1 text-xs tracking-wide text-gray-700">
                +977-9841359405
                </p>
                <p className="mb-4 text-xs font-bold text-gray-700">
                ramcshah@yahoo.com
                </p>
              </li>
            </ul>
            <ul className="space-y-2">
              <li className="flex flex-col items-end">
                <span className="mr-1">
                  <svg
                    className="w-5 h-5 mt-px text-blue-900"
                    stroke="currentColor"
                    viewBox="0 0 52 52"
                  >
                    <polygon
                      strokeWidth="4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="none"
                      points="29 13 14 29 25 29 23 39 38 23 27 23"
                    />
                  </svg>
                </span>
                <p className="mb-1 text-lg font-bold text-gray-700">
                MR. SHIVRAM DHUKWA
                </p>
                <p className="mb-1 text-xs tracking-wide text-gray-700">
                +977-9841229771
                </p>
                <p className="mb-4 text-xs font-bold text-gray-700">
                srtdhukhwa@gmail.com
                </p>
              </li>
              <li className="flex flex-col items-end">
                <span className="mr-1">
                  <svg
                    className="w-5 h-5 mt-px text-blue-900"
                    stroke="currentColor"
                    viewBox="0 0 52 52"
                  >
                    <polygon
                      strokeWidth="4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="none"
                      points="29 13 14 29 25 29 23 39 38 23 27 23"
                    />
                  </svg>
                </span>
                <p className="mb-1 text-lg font-bold text-gray-700">
                MR. KHYAM PRASAD UPRETI
                </p>
                <p className="mb-1 text-xs tracking-wide text-gray-700">
                +977-9845712811
                </p>
                <p className="mb-4 text-xs font-bold text-gray-700">
                khyamupreti2016@gmail.com
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default CurrentCommittee;
