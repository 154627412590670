import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <nav
        class="flex pt-[120px] justify-center items-center"
        aria-label="Breadcrumb"
      >
        <ol class="inline-flex items-center space-x-1 md:space-x-3 p-6">
          <li class="inline-flex items-center">
            <Link
              to="/"
              class="inline-flex items-center text-sm font-medium text-gray-900 hover:text-Primary-A"
            >
              <svg
                class="mr-2 w-4 h-4"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
              </svg>
              Home
            </Link>
          </li>
          <li aria-current="page">
            <div class="flex items-center">
              <svg
                class="w-6 h-6 text-blue-800"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span class="ml-1 text-sm font-medium text-Primary-A md:ml-2">
                About Us
              </span>
            </div>
          </li>
        </ol>
      </nav>
      <div className="px-4 py-12 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
        <div className="grid gap-5 row-gap-8 lg:grid-cols-2">
          <div className="flex flex-col justify-center">
            <div className="max-w-xl mb-6">
              <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                <span className="relative inline-block uppercase text-Primary-A">
                  About Us
                </span>
              </h2>
              <p className="text-base text-gray-700 md:text-lg">
                Nepal Association of Radiotherapy Technologists (NART) is a
                non-government, not-for-profit and voluntary professional
                organization of the Radiotherapy Technologists (RTTs) in Nepal.
                It is the only and national organization that includes all
                radiotherapy technology personnel in Nepal under a common roof
                for their professional interests and benefits. The association
                was established in 2010 AD.
              </p>
              <p className="text-base text-gray-700 md:text-lg mt-6">
                NART works to advance the profession through leadership in
                advocacy, education and research.
              </p>
              <p className="text-base text-gray-700 md:text-lg mt-6">
                The association supports the RTTs to provide the uppermost
                quality of patient care throughout their careers by providing a
                highly respected certification process, excellent continuing
                education, appropriate and evidence-based practice resources,
                and valuable member services.
              </p>
            </div>
          </div>
          <div>
            <img
              className="object-cover w-full h-56 rounded shadow-lg my-6 sm:h-96"
              src="https://images.pexels.com/photos/927022/pexels-photo-927022.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=3&amp;h=750&amp;w=1260"
              alt=""
            />
          </div>
        </div>
        <div className="px-4 py-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-4 lg:py-10">
          <div className="p-8 rounded shadow-xl sm:p-16">
            <div className="flex flex-col lg:flex-row items-center justify-center">
              <div className="mb-6 lg:mb-0 lg:w-1/2 lg:pr-5">
                <h2 className="  font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-6xl sm:leading-none">
                  <span className="inline-block text-blue-900 pt-[15px] pl-[95px] ">
                    MISSION
                  </span>
                </h2>
              </div>
              <div className="lg:w-1/2">
                <p className="mb-4 text-base text-gray-700">
                  The association is committed to approach for facilitating for
                  capacity building of radiotherapy technologists, safe and
                  stirred workplace environment and equilibrium professional
                  growth opportunity to all according to the law and act.
                </p>
                <p className="mb-4 text-base text-gray-700">
                  It is also committed for approaching for organizational
                  recognition from concerned authorities like Ministry of Health
                  and Population, Ministry of Education, Science and Technology,
                  the Department of Health Services, Public Service Commission,
                  Nepal Academy of Science and Technology, Nepal Health
                  Professional Council and others.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="px-4 py-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-4 lg:py-10">
          <div className="p-8 rounded shadow-xl sm:p-16">
            <div className="flex flex-col lg:flex-row items-center justify-center">
              <div className="mb-6 lg:mb-0 lg:w-1/2 lg:pr-5">
                <h2 className="  font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-6xl sm:leading-none">
                  <span className="inline-block text-blue-900 pt-[15px] pl-[90px]">
                    VISION
                  </span>
                </h2>
              </div>
              <div className="lg:w-1/2">
                <p className="mb-4 text-base text-gray-700">
                  NART strives to be the premier professional association for
                  the radiation therapy technologist’s community through
                  education, advocacy, research and innovation. Besides, it also
                  intends to make alliance with other health professional
                  associations in Nepal, alliance with other radiation therapy
                  professional associations in abroad as well as other
                  international organizations like IAEA, WHO, UICC,
                  international aid agencies et cetera for the skill and
                  technology transfer, knowledge sharing and occupational
                  solidarity.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-10">
        <div className="mx-auto sm:text-center lg:max-w-2xl">
          <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
            <div>
              <p className="mb-8 text-4xl font-extrabold text-Primary-A leading-none md:pl-2">
                Message from the President
              </p>
            </div>
          </div>
          <div className="mb-4 transition-shadow duration-300 hover:shadow-xl lg:mb-6">
            <img
              className="object-cover w-full h-56 rounded shadow-lg sm:h-64 md:h-80 lg:h-96"
              src="https://images.pexels.com/photos/3727459/pexels-photo-3727459.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;h=750&amp;w=1260"
              alt=""
            />
          </div>
          <p className="text-base text-gray-700 md:text-lg">
            Initially, the development of radiotherapy treatment profession in
            Nepal was slow but it has been now gradually progressing. In Nepal,
            the first radiation therapy service was started in 1976 at Maternity
            Hospital with Brachytherapy service which was donated by the USA. In
            1991, Bir Hospital started first tele-therapy service with a
            Cobalt60 machine. At the moment, there are 6 radiotherapy treatment
            centers providing the service across the country under government
            and non-government sectors and also few centers are approaching to
            commence the service at different locations in the near future.
          </p>
          <p className="text-base text-gray-700 md:text-lg mt-4">
            Nepal has witnessed the mid-generation radiotherapy treatment
            equipment and technologies as treatment by using Cobalt60 and 2D
            manual treatment planning in the early days. As the time progresses,
            the new centers are stablishing state-of-the- art facilities with
            modern equipment and technologies. We, in Nepal, do not have longer
            history of the occupation, but now knowing the importance of
            radiotherapy treatment in cancer management and the crucial input of
            radiotherapy technologist’s in administrating the treatment, the
            contribution of the radiotherapy personnel is being recognized and
            expanded. Radiation therapy treatment is a multi-disciplinary
            specialists’ teamwork and RTTs are one of those vitals – it is
            accepted and it is appreciated in Nepal.
          </p>
        </div>
      </div>
      <div className="px-4 pb-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:pb-20">
        <h5 className="mb-8 text-4xl font-extrabold text-Primary-A leading-none md:pl-2">
          Aims and objectives:
        </h5>
        <div className="grid gap-4 row-gap-4 lg:grid-cols-4">
          <ul className="space-y-3">
            <li className="flex items-start">
              <span className="mr-1">
                <svg
                  className="w-5 h-5 mt-px text-blue-900"
                  stroke="currentColor"
                  viewBox="0 0 52 52"
                >
                  <polygon
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                    points="29 13 14 29 25 29 23 39 38 23 27 23"
                  />
                </svg>
              </span>
              To approach for initiating curriculum-based education and training
              programs for Radiation Therapy Technology course at the
              educational institutes in Nepal and to advocate for designing
              quality, uniform and standard syllabus for the course.
            </li>
            <li className="flex items-start">
              <span className="mr-1">
                <svg
                  className="w-5 h-5 mt-px text-blue-900"
                  stroke="currentColor"
                  viewBox="0 0 52 52"
                >
                  <polygon
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                    points="29 13 14 29 25 29 23 39 38 23 27 23"
                  />
                </svg>
              </span>
              To approach to the government and non-government radiotherapy
              treatment service centers for establishing a standard and uniform
              process of staff recruitment, specific role and function, equal
              opportunity of professional growth and benefits as well as safe
              workplace environment.
            </li>
          </ul>
          <ul className="space-y-3">
            <li className="flex items-start">
              <span className="mr-1">
                <svg
                  className="w-5 h-5 mt-px text-blue-900"
                  stroke="currentColor"
                  viewBox="0 0 52 52"
                >
                  <polygon
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                    points="29 13 14 29 25 29 23 39 38 23 27 23"
                  />
                </svg>
              </span>
              To approach to the concerned authorities for formulation of
              scientific policy of certification, legitimately employment
              opportunities and valued income implementation.
            </li>
            <li className="flex items-start">
              <span className="mr-1">
                <svg
                  className="w-5 h-5 mt-px text-blue-900"
                  stroke="currentColor"
                  viewBox="0 0 52 52"
                >
                  <polygon
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                    points="29 13 14 29 25 29 23 39 38 23 27 23"
                  />
                </svg>
              </span>
              To conduct education and knowledge sharing programs regularly by
              organizing CMEs and CPDs amongst RTTs and other cancer management
              professionals.
            </li>
          </ul>
          <ul className="space-y-3">
            <li className="flex items-start">
              <span className="mr-1">
                <svg
                  className="w-5 h-5 mt-px text-blue-900"
                  stroke="currentColor"
                  viewBox="0 0 52 52"
                >
                  <polygon
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                    points="29 13 14 29 25 29 23 39 38 23 27 23"
                  />
                </svg>
              </span>
              To organize meetings, conferences, seminars, workshops for
              professional development and solidarity.
            </li>
            <li className="flex items-start">
              <span className="mr-1">
                <svg
                  className="w-5 h-5 mt-px text-blue-900"
                  stroke="currentColor"
                  viewBox="0 0 52 52"
                >
                  <polygon
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                    points="29 13 14 29 25 29 23 39 38 23 27 23"
                  />
                </svg>
              </span>
              To advocate for establishing the National Radiation Regularity
              Body for safe transfer and handling of radiation materials and to
              encourage the RTTs to adhere to the guidelines of radiation
              protection and safety in the “controlled area” at all times.
            </li>
          </ul>
          <ul className="space-y-3">
            <li className="flex items-start">
              <span className="mr-1">
                <svg
                  className="w-5 h-5 mt-px text-blue-900"
                  stroke="currentColor"
                  viewBox="0 0 52 52"
                >
                  <polygon
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                    points="29 13 14 29 25 29 23 39 38 23 27 23"
                  />
                </svg>
              </span>
              To promote RTT personnel in their career growth programs and to
              support the RTT students as well as trainees in the academic
              enhancement endeavors.
            </li>
            <li className="flex items-start">
              <span className="mr-1">
                <svg
                  className="w-5 h-5 mt-px text-blue-900"
                  stroke="currentColor"
                  viewBox="0 0 52 52"
                >
                  <polygon
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                    points="29 13 14 29 25 29 23 39 38 23 27 23"
                  />
                </svg>
              </span>
              To be active to updating news about newer technologies and
              advancement in the field of radiotherapy.
            </li>
          </ul>
        </div>
      </div>
      <div className="px-4 pb-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:pb-20">
        <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
          <h2 className="max-w-lg mb-6 text-Primary-A font-sans text-3xl font-extrabold leading-none tracking-tight sm:text-4xl md:mx-auto">
            WHO WE ARE
          </h2>
          <p className="text-base text-gray-700 md:text-lg">
            Radiotherapy Technologists - Introduction, Role and Functions:
          </p>
        </div>
        <div>
          <div className="sm:text-center">
            <p className="max-w-md mb-1 text-sm text-gray-900 sm:mx-auto">
              A Radiotherapy Technologist or Radiation Therapist or Radiation
              Therapy Technologist or Therapy Radiographer (RTT) is a trained
              and expert in radiation therapy treatment studies that works in
              the department of Radiation Oncology along with other health
              professionals like Radiation Oncologists, Medical Physicists and
              other Radiation Oncology staffs to treat cancer patients by
              radiation. RTTs are the part of the core team that administrate
              the treatment to the patients with cancer and other diseases by
              the use of high energy ionizing radiation. Radiation therapy is a
              kind of treatment that is used to treat cancer by shrinking or
              removing the cancer cells or tumors by the application of ionizing
              radiation.
            </p>
          </div>
          <div className="sm:text-center">
            <p className="max-w-md mb-1 text-sm text-gray-900 sm:mx-auto">
              RTTs are key members in the field of radiation therapy who are
              responsible for safely operation and handling of highly
              sophisticated radiotherapy equipment and its functionality,
              simulation and treatment planning of patients, delivering
              radiation therapy treatment, counseling the patients and
              performing other committed functions in the department. They
              execute accurate and precise treatment plans provided by the
              clinicians.
            </p>
          </div>
        </div>
      </div>
      <div className="px-4 pb-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:pb-20">
        <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
          <h2 className="max-w-lg mb-2 font-sans text-3xl font-extrabold leading-none tracking-tight text-Primary-A sm:text-4xl md:mx-auto">
            ROLE AND FUNCTIONS OF RTTS
          </h2>
          <p className="max-w-md mb-1 text-sm text-gray-900 sm:mx-auto">
            The role and functions of RTTs may vary from center to center and
            from country to country. However, the roles majorly include and are
            not limited to:
          </p>
        </div>
        <div className="grid row-gap-8 sm:row-gap-0 sm:grid-cols-2 lg:grid-cols-3">
          <div className="p-8 border-blue-900 border-b-4 sm:border-r-4">
            <div className="max-w-md text-center">
              <h6 className="mb-2 font-semibold leading-5">
                Providing individualized radiation therapy treatment plans to
                the cancer patients and administering the treatment in
                accordance with the prescriptions prepared by the radiation
                oncologists.
              </h6>
            </div>
          </div>
          <div className="p-8 border-blue-900 border-b-4 lg:border-r-4">
            <div className="max-w-md text-center">
              <h6 className="mb-2 font-semibold leading-5">
                Explaining the patients about treatment procedures and answering
                to their queries.
              </h6>
            </div>
          </div>
          <div className="p-8 border-blue-900 border-b-4 sm:border-r-4 lg:border-r-0">
            <div className="max-w-md text-center">
              <h6 className="mb-2 font-semibold leading-5">
                Using medical imaging equipment to ensure treatment is delivered
                accurately and correctly.
              </h6>
            </div>
          </div>
          <div className="p-8 border-blue-900 border-b-4 lg:border-r-4">
            <div className="max-w-md text-center">
              <h6 className="mb-2 font-semibold leading-5">
                Explaining the patients about potential treatment side effects,
                assessing the reactions, and advising them on self-care as well
                as methods of alleviation, and referring to the consultant for
                further assistance.
              </h6>
            </div>
          </div>
          <div className="p-8 border-blue-900 border-b-4 sm:border-r-4">
            <div className="max-w-md text-center">
              <h6 className="mb-2 font-semibold leading-5">
                Counseling the patients about suitable diet and nutrition during
                and after the treatment.
              </h6>
            </div>
          </div>
          <div className="p-8 border-blue-900 border-b-4">
            <div className="max-w-md text-center">
              <h6 className="mb-2 font-semibold leading-5">
                Monitoring treatment progress and suggesting necessary
                amendments to treatment plans.
              </h6>
            </div>
          </div>
          <div className="p-8 border-blue-900 border-b-4 sm:border-r-4 lg:border-b-0">
            <div className="max-w-md text-center">
              <h6 className="mb-2 font-semibold leading-5">
                To adhering to the principles of radiation protection and safety
                policy in the “controlled area” at all times.
              </h6>
            </div>
          </div>
          <div className="p-8 border-blue-900 border-b-4 sm:border-b-4 lg:border-r-4 lg:border-b-0">
            <div className="max-w-md text-center">
              <h6 className="mb-2 font-semibold leading-5">
                Performing quality assurance tests of simulation and treatment
                equipment to ensure their proper order and function.
              </h6>
            </div>
          </div>
          <div className="p-8 border-blue-900 sm:border-r-4 lg:border-r-0">
            <div className="max-w-md text-center">
              <h6 className="mb-2 font-semibold leading-5">
                Providing moral support to the patients and their families
                throughout and beyond the treatment.
              </h6>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
