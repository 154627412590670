import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

const Navbar = ({ Logo }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [navbar, setNavbar] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 66) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  useEffect(() => {
    changeBackground();
    // adding the event when scroll change background
    window.addEventListener("scroll", changeBackground);
  });
  return (
    <>
      <div
        className={
          navbar
            ? "fixed top-0 right-0 left-0 bg-gray-900 z-40"
            : "fixed top-0 right-0 left-0 bg-gray-900 z-40"
        }
      >
        <div class="px-4 py-5 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
          <div class="flex items-center justify-between">
            <NavLink
              to="/"
              aria-label="Company"
              title="Company"
              class="inline-flex items-center"
            >
              <img src={Logo} className="rounded-full w-16 lg:w-20" alt="" />
            </NavLink>
            <ul class=" items-center uppercase hidden space-x-8 lg:flex">
              <li>
                <NavLink
                  to="/"
                  aria-label="home"
                  title="home"
                  className={({ isActive }) =>
                    isActive
                      ? "tracking-wide font-bold text-Primary-A transition-colors duration-200"
                      : "tracking-wide font-bold text-gray-300 transition-colors duration-200 hover:text-Primary-A"
                  }
                >
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/about"
                  aria-label="About Us"
                  title="About Us"
                  className={({ isActive }) =>
                    isActive
                      ? "tracking-wide font-bold text-Primary-A transition-colors duration-200"
                      : "tracking-wide font-bold text-gray-300 transition-colors duration-200 hover:text-Primary-A"
                  }
                >
                  About
                </NavLink>
              </li>
              <li>
                <div class="dropdown inline-block relative">
                  <button class="font-bold text-gray-300 uppercase transition-colors duration-200">
                    <span class="mr-1">Organization</span>
                  </button>
                  <div class="dropdown-menu absolute hidden text-gray-300 pt-3">
                    <div class="z-10 bg-black rounded shadow w-60">
                      <ul class="py-6 px-2 text-sm text-gray-700 dark:text-gray-400">
                        <li>
                          <NavLink
                            to="/currentcommittee"
                            aria-label="Current Committee"
                            title="Current Committee"
                            className={({ isActive }) =>
                              isActive
                                ? "block px-4 py-2 font-bold text-Primary-A transition-colors duration-200"
                                : "block px-4 py-2 font-bold text-gray-300 transition-colors duration-200 hover:text-Primary-A"
                            }
                          >
                            Current Committee
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/pastcommittee"
                            aria-label="Past Committee"
                            title="Past Committee"
                            className={({ isActive }) =>
                              isActive
                                ? "block px-4 py-2 font-bold text-Primary-A transition-colors duration-200"
                                : "block px-4 py-2 font-bold text-gray-300 transition-colors duration-200 hover:text-Primary-A"
                            }
                          >
                            Past Committee
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/foundercommittee"
                            aria-label="Founder Committee"
                            title="Founder Committee"
                            className={({ isActive }) =>
                              isActive
                                ? "block px-4 py-2 font-bold text-Primary-A transition-colors duration-200"
                                : "block px-4 py-2 font-bold text-gray-300 transition-colors duration-200 hover:text-Primary-A"
                            }
                          >
                            Founder Committee
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class="dropdown inline-block relative">
                  <button class="font-bold text-gray-300 uppercase transition-colors duration-200">
                    <span class="mr-1">Membership</span>
                  </button>
                  <div class="dropdown-menu absolute hidden text-gray-300 pt-3">
                    <div class="z-10 bg-black rounded shadow w-60">
                      <ul class="py-6 px-2 text-sm text-gray-700 dark:text-gray-400">
                        <li>
                          <NavLink
                            to="/newregistration"
                            aria-label="New Registration"
                            title="New Registration"
                            className={({ isActive }) =>
                              isActive
                                ? "block px-4 py-2 font-bold text-Primary-A transition-colors duration-200"
                                : "block px-4 py-2 font-bold text-gray-300 transition-colors duration-200 hover:text-Primary-A"
                            }
                          >
                            New Registration
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/registeredmember"
                            aria-label="Registered Member"
                            title="Registered Member"
                            className={({ isActive }) =>
                              isActive
                                ? "block px-4 py-2 font-bold text-Primary-A transition-colors duration-200"
                                : "block px-4 py-2 font-bold text-gray-300 transition-colors duration-200 hover:text-Primary-A"
                            }
                          >
                            Registered Member
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class="dropdown inline-block relative">
                  <button class="font-bold text-gray-300 uppercase transition-colors duration-200">
                    <span class="mr-1">Events</span>
                  </button>
                  <div class="dropdown-menu absolute hidden text-gray-300 pt-3">
                    <div class="z-10 bg-black rounded shadow w-60">
                      <ul class="py-6 px-2 text-sm text-gray-700 dark:text-gray-400">
                        <li>
                          <NavLink
                            to="/upcomingevents"
                            aria-label="UpComing Events"
                            title="UpComing Events"
                            className={({ isActive }) =>
                              isActive
                                ? "block px-4 py-2 font-bold text-Primary-A transition-colors duration-200"
                                : "block px-4 py-2 font-bold text-gray-300 transition-colors duration-200 hover:text-Primary-A"
                            }
                          >
                            Upcoming Events
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/pastevents"
                            aria-label="Past Events"
                            title="Past Events"
                            className={({ isActive }) =>
                              isActive
                                ? "block px-4 py-2 font-bold text-Primary-A transition-colors duration-200"
                                : "block px-4 py-2 font-bold text-gray-300 transition-colors duration-200 hover:text-Primary-A"
                            }
                          >
                            Past Events
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <NavLink
                  to="/donation"
                  aria-label="Donation"
                  title="Donation"
                  className={({ isActive }) =>
                    isActive
                      ? "tracking-wide font-bold text-Primary-A transition-colors duration-200"
                      : "tracking-wide font-bold text-gray-300 transition-colors duration-200 hover:text-Primary-A"
                  }
                >
                  donation
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/education"
                  aria-label="Education"
                  title="Education"
                  className={({ isActive }) =>
                    isActive
                      ? "tracking-wide font-bold text-Primary-A transition-colors duration-200"
                      : "tracking-wide font-bold text-gray-300 transition-colors duration-200 hover:text-Primary-A"
                  }
                >
                  education
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/gallery"
                  aria-label="Gallery"
                  title="Gallery"
                  className={({ isActive }) =>
                    isActive
                      ? "tracking-wide font-bold text-Primary-A transition-colors duration-200"
                      : "tracking-wide font-bold text-gray-300 transition-colors duration-200 hover:text-Primary-A"
                  }
                >
                  gallery
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/contact"
                  aria-label="Contact Us"
                  title="Contact Us"
                  className={({ isActive }) =>
                    isActive
                      ? "tracking-wide font-bold text-Primary-A transition-colors duration-200"
                      : "tracking-wide font-bold text-gray-300 transition-colors duration-200 hover:text-Primary-A"
                  }
                >
                  contact
                </NavLink>
              </li>
            </ul>

            <div class="lg:hidden">
              <button
                aria-label="Open Menu"
                title="Open Menu"
                class="p-2 -mr-1 transition duration-200 rounded focus:outline-none focus:shadow-outline hover:bg-deep-purple-50 focus:bg-deep-purple-50"
                onClick={() => setIsMenuOpen(true)}
              >
                <svg class="w-5 text-gray-300" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z"
                  />
                  <path
                    fill="currentColor"
                    d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"
                  />
                  <path
                    fill="currentColor"
                    d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z"
                  />
                </svg>
              </button>
              {isMenuOpen && (
                <div class="fixed top-0 left-0 w-full z-40">
                  <div class="p-5 bg-gray-900 rounded shadow-sm">
                    <div class="flex items-center justify-between mb-4">
                      <NavLink
                        to="/"
                        aria-label="Company"
                        title="Company"
                        class="inline-flex items-center"
                        onClick={() => setIsMenuOpen(false)}
                      >
                        <img src={Logo} className="rounded-full w-20" alt="" />
                      </NavLink>
                      <div>
                        <button
                          aria-label="Close Menu"
                          title="Close Menu"
                          class="p-2 -mt-2 -mr-2 transition duration-200 rounded hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                          onClick={() => setIsMenuOpen(false)}
                        >
                          <svg class="w-5 text-gray-300" viewBox="0 0 24 24">
                            <path
                              fill="currentColor"
                              d="M19.7,4.3c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3 c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L13.4,12l6.3-6.3C20.1,5.3,20.1,4.7,19.7,4.3z"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <nav>
                      <ul class="space-y-4 flex flex-col justify-center uppercase items-center">
                        <li>
                          <NavLink
                            to="/"
                            aria-label="home"
                            onClick={() => setIsMenuOpen(false)}
                            title="home"
                            className={({ isActive }) =>
                              isActive
                                ? "tracking-wide font-bold text-Primary-A transition-colors duration-200"
                                : "tracking-wide font-bold text-gray-300 transition-colors duration-200 hover:text-Primary-A"
                            }
                          >
                            Home
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/about"
                            aria-label="About Us"
                            onClick={() => setIsMenuOpen(false)}
                            title="About Us"
                            className={({ isActive }) =>
                              isActive
                                ? "tracking-wide font-bold text-Primary-A transition-colors duration-200"
                                : "tracking-wide font-bold text-gray-300 transition-colors duration-200 hover:text-Primary-A"
                            }
                          >
                            About
                          </NavLink>
                        </li>
                        <li>
                          <div class="dropdown inline-block relative">
                            <button class="font-bold text-gray-300 uppercase transition-colors duration-200">
                              <span class="mr-1">Organization</span>
                            </button>
                            <div class="dropdown-menu absolute hidden text-gray-300 pt-3">
                              <div class="z-10 bg-black rounded shadow w-60">
                                <ul class="py-6 px-2 text-sm text-gray-700 dark:text-gray-400">
                                  <li>
                                    <NavLink
                                      to="/currentcommittee"
                                      aria-label="Current Committee"
                                      onClick={() => setIsMenuOpen(false)}
                                      title="Current Committee"
                                      className={({ isActive }) =>
                                        isActive
                                          ? "block px-4 py-2 font-bold text-Primary-A transition-colors duration-200"
                                          : "block px-4 py-2 font-bold text-gray-300 transition-colors duration-200 hover:text-Primary-A"
                                      }
                                    >
                                      Current Committee
                                    </NavLink>
                                  </li>
                                  <li>
                                    <NavLink
                                      to="/pastcommittee"
                                      aria-label="Past Committee"
                                      onClick={() => setIsMenuOpen(false)}
                                      title="Past Committee"
                                      className={({ isActive }) =>
                                        isActive
                                          ? "block px-4 py-2 font-bold text-Primary-A transition-colors duration-200"
                                          : "block px-4 py-2 font-bold text-gray-300 transition-colors duration-200 hover:text-Primary-A"
                                      }
                                    >
                                      Past Committee
                                    </NavLink>
                                  </li>
                                  <li>
                                    <NavLink
                                      to="/foundercommittee"
                                      aria-label="Founder Committee"
                                      onClick={() => setIsMenuOpen(false)}
                                      title="Founder Committee"
                                      className={({ isActive }) =>
                                        isActive
                                          ? "block px-4 py-2 font-bold text-Primary-A transition-colors duration-200"
                                          : "block px-4 py-2 font-bold text-gray-300 transition-colors duration-200 hover:text-Primary-A"
                                      }
                                    >
                                      Founder Committee
                                    </NavLink>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="dropdown inline-block relative">
                            <button class="font-bold text-gray-300 uppercase transition-colors duration-200">
                              <span class="mr-1">Membership</span>
                            </button>
                            <div class="dropdown-menu absolute hidden text-gray-300 pt-3">
                              <div class="z-10 bg-black rounded shadow w-60">
                                <ul class="py-6 px-2 text-sm text-gray-700 dark:text-gray-400">
                                  <li>
                                    <NavLink
                                      to="/newregistration"
                                      aria-label="New Registration"
                                      onClick={() => setIsMenuOpen(false)}
                                      title="New Registration"
                                      className={({ isActive }) =>
                                        isActive
                                          ? "block px-4 py-2 font-bold text-Primary-A transition-colors duration-200"
                                          : "block px-4 py-2 font-bold text-gray-300 transition-colors duration-200 hover:text-Primary-A"
                                      }
                                    >
                                      New Registration
                                    </NavLink>
                                  </li>
                                  <li>
                                    <NavLink
                                      to="/registeredmember"
                                      aria-label="Registered Member"
                                      onClick={() => setIsMenuOpen(false)}
                                      title="Registered Member"
                                      className={({ isActive }) =>
                                        isActive
                                          ? "block px-4 py-2 font-bold text-Primary-A transition-colors duration-200"
                                          : "block px-4 py-2 font-bold text-gray-300 transition-colors duration-200 hover:text-Primary-A"
                                      }
                                    >
                                      Registered Member
                                    </NavLink>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="dropdown inline-block relative">
                            <button class="font-bold text-gray-300 uppercase transition-colors duration-200">
                              <span class="mr-1">Events</span>
                            </button>
                            <div class="dropdown-menu absolute hidden text-gray-300 pt-3">
                              <div class="z-10 bg-black rounded shadow w-60">
                                <ul class="py-6 px-2 text-sm text-gray-700 dark:text-gray-400">
                                  <li>
                                    <NavLink
                                      to="/upcomingevents"
                                      aria-label="UpComing Events"
                                      onClick={() => setIsMenuOpen(false)}
                                      title="UpComing Events"
                                      className={({ isActive }) =>
                                        isActive
                                          ? "block px-4 py-2 font-bold text-Primary-A transition-colors duration-200"
                                          : "block px-4 py-2 font-bold text-gray-300 transition-colors duration-200 hover:text-Primary-A"
                                      }
                                    >
                                      Upcoming Events
                                    </NavLink>
                                  </li>
                                  <li>
                                    <NavLink
                                      to="/pastevents"
                                      aria-label="Past Events"
                                      onClick={() => setIsMenuOpen(false)}
                                      title="Past Events"
                                      className={({ isActive }) =>
                                        isActive
                                          ? "block px-4 py-2 font-bold text-Primary-A transition-colors duration-200"
                                          : "block px-4 py-2 font-bold text-gray-300 transition-colors duration-200 hover:text-Primary-A"
                                      }
                                    >
                                      Past Events
                                    </NavLink>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <NavLink
                            to="/donation"
                            aria-label="Donation"
                            onClick={() => setIsMenuOpen(false)}
                            title="Donation"
                            className={({ isActive }) =>
                              isActive
                                ? "tracking-wide font-bold text-Primary-A transition-colors duration-200"
                                : "tracking-wide font-bold text-gray-300 transition-colors duration-200 hover:text-Primary-A"
                            }
                          >
                            donation
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/education"
                            aria-label="Education"
                            onClick={() => setIsMenuOpen(false)}
                            title="Education"
                            className={({ isActive }) =>
                              isActive
                                ? "tracking-wide font-bold text-Primary-A transition-colors duration-200"
                                : "tracking-wide font-bold text-gray-300 transition-colors duration-200 hover:text-Primary-A"
                            }
                          >
                            education
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/gallery"
                            aria-label="Gallery"
                            onClick={() => setIsMenuOpen(false)}
                            title="Gallery"
                            className={({ isActive }) =>
                              isActive
                                ? "tracking-wide font-bold text-Primary-A transition-colors duration-200"
                                : "tracking-wide font-bold text-gray-300 transition-colors duration-200 hover:text-Primary-A"
                            }
                          >
                            gallery
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/contact"
                            aria-label="Contact Us"
                            onClick={() => setIsMenuOpen(false)}
                            title="Contact Us"
                            className={({ isActive }) =>
                              isActive
                                ? "tracking-wide font-bold text-Primary-A transition-colors duration-200"
                                : "tracking-wide font-bold text-gray-300 transition-colors duration-200 hover:text-Primary-A"
                            }
                          >
                            contact
                          </NavLink>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
